import queryString from 'query-string';

const Document = {
  async loadAllDocuments(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/document?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading documents information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async searchDocument(baseApiUrl, searchFields, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        token: loginInfo['token'],
      };

      for(let i = 0; i < Object.keys(searchFields).length; i++){
        const key = Object.keys(searchFields)[i];
        const value = searchFields[key];
        queryList[key] = value;
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/document?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading document information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadDocument(baseApiUrl, documentId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/document/${documentId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading document information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createDocument(baseApiUrl, documentInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        token: loginInfo['token'],
        documentInfo: documentInfo,
      };

      const apiUrl = `${baseApiUrl}/document`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create document information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateDocument(baseApiUrl, documentId, documentInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        documentInfo: documentInfo,
      };

      const apiUrl = `${baseApiUrl}/document/${documentId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update document information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteDocument(baseApiUrl, documentId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/document/${documentId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete document information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
}

export default Document;
